.googleButtonContainer {
  width: 100%;
  max-width: 360px;
  margin-top: 10%;
}

.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: 10px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: all 0.3s ease;
}

.googleButton:hover {
  background-color: #f8f8f8;
  border-color: #ccc;
}

.googleButton svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: #333;
}

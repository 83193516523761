.mainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  min-width: 1000px;
}

.leftGrid {
  height: 100%;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  position: absolute;
  font-size: 14px;
  bottom: 0;
  left: 0;
  margin-left: 1%;
  padding: 20px;
  font-weight: 400;
  color: var(--text-tertiary-600);
}

.formContainer {
  max-width: 360px;
  width: 100%;
  background-color: hsl(0deg 0% 98%);
  height: 44px;
  border-radius: 5px;
}

.companyLogo {
  width: 83px;
  height: 32px;
  margin-bottom: 15%;
}

.loginContent {
  width: 100%;
  font-weight: 400px;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-tertiary-600, #475467);
}

.leftGridContent {
  max-width: 360px;
}

.loginSubtitle {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 4%;
}

.rightGrid {
  height: 100%;
  width: 100%;
  flex: 3;
  background-color: var(--blue-0, #f2f4f7);
  overflow: hidden;
}

.rightGridContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15%;
  margin-top: 5%;
}

.rightGridHeader {
  margin-top: 5%;
  font-size: 30px;
  font-weight: 500px;
  line-height: 38px;
}

.rightGridSubHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 5%;
}

.rightGridSubHeader span {
  font-weight: 600;
  font-size: 18px;
}

.bannerAnchor {
  margin-top: 10%;
  margin-left: auto;
}

.bottomBanner {
  margin-bottom: 0;
  height: 75vh;
}

@media screen and (max-width: 1000px) {
  .mainContainer {
    overflow-x: scroll;
  }
}
